// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.affiliate-reference_uV6zG{display:flex;flex-direction:column;justify-content:space-around;background-color:var(--Layer1);border-radius:5px}.affiliate-reference__title_Q18ce{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;color:var(--TextSecondary)}.affiliate-reference__wrapper_rzh4p{display:flex;align-items:center;padding:8px 0 12px;color:var(--TextDefault)}.affiliate-reference__wrapper-field_WbKqN{line-height:16px;font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;flex:1;padding:10px 12px;overflow:hidden;text-overflow:ellipsis;background:var(--Layer2);border-top-left-radius:4px;border-bottom-left-radius:4px}.affiliate-reference__wrapper-copy_auDj9{border-top-left-radius:0;border-bottom-left-radius:0}.affiliate-reference__wrapper-copy_auDj9 svg{fill:var(--BrandText)}.affiliate-reference__wrapper-copy_auDj9.button--icon-only_V0jLH{width:52px}html.app__layout--desktop .affiliate-reference__wrapper-copy_auDj9.button--icon-only_V0jLH{width:44px}html.app__layout--desktop .affiliate-reference__wrapper_rzh4p{padding:16px 0}.affiliate-reference__icon_k8ssn{fill:var(--TextSecondary)}.affiliate-reference__info_I6w_E{display:flex;align-items:center;color:var(--TextSecondary);cursor:pointer}.affiliate-reference__info-text_mYmik{font-size:13px;font-weight:400;line-height:20px;letter-spacing:normal;margin-left:6px;-webkit-text-decoration:underline;text-decoration:underline}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-reference": `affiliate-reference_uV6zG`,
	"affiliate-reference__title": `affiliate-reference__title_Q18ce`,
	"affiliate-reference__wrapper": `affiliate-reference__wrapper_rzh4p`,
	"affiliate-reference__wrapper-field": `affiliate-reference__wrapper-field_WbKqN`,
	"affiliate-reference__wrapper-copy": `affiliate-reference__wrapper-copy_auDj9`,
	"button--icon-only": `button--icon-only_V0jLH`,
	"affiliate-reference__icon": `affiliate-reference__icon_k8ssn`,
	"affiliate-reference__info": `affiliate-reference__info_I6w_E`,
	"affiliate-reference__info-text": `affiliate-reference__info-text_mYmik`
};
export default ___CSS_LOADER_EXPORT___;
