// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .affiliate-customer-mobile_g5fZq{margin:0 8px}html.app__layout--desktop .affiliate-customer-mobile__headline_Awj4F{top:30px;padding-bottom:0}html.app__layout--desktop .affiliate-customer-mobile__tabs_x6Csv{margin-bottom:4px}html.app__layout--desktop .affiliate-customer-mobile__select-group_ziFVp{display:flex}html.app__layout--desktop .affiliate-customer-mobile__card-wrapper_yT4Hw{padding:8px 12px;cursor:default}html.app__layout--desktop .affiliate-customer-mobile__select_HBhqM{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;position:relative}html.app__layout--desktop .affiliate-customer__counter_AN5pe{margin:0 0 12px 8px}html.app__layout--desktop .affiliate-customer__counter__label_XcdGZ{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal}html.app__layout--desktop .affiliate-customer__not-found_YttI3{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-top:8px;margin-left:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-customer-mobile": `affiliate-customer-mobile_g5fZq`,
	"affiliate-customer-mobile__headline": `affiliate-customer-mobile__headline_Awj4F`,
	"affiliate-customer-mobile__tabs": `affiliate-customer-mobile__tabs_x6Csv`,
	"affiliate-customer-mobile__select-group": `affiliate-customer-mobile__select-group_ziFVp`,
	"affiliate-customer-mobile__card-wrapper": `affiliate-customer-mobile__card-wrapper_yT4Hw`,
	"affiliate-customer-mobile__select": `affiliate-customer-mobile__select_HBhqM`,
	"affiliate-customer__counter": `affiliate-customer__counter_AN5pe`,
	"affiliate-customer__counter__label": `affiliate-customer__counter__label_XcdGZ`,
	"affiliate-customer__not-found": `affiliate-customer__not-found_YttI3`
};
export default ___CSS_LOADER_EXPORT___;
