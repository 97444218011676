// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .affiliate-activation_WsSAA{padding:16px 24px}html.app__layout--desktop .affiliate-activation__bottom_rgNX4{margin:0 8px}html.app__layout--desktop .affiliate-activation__bottom__checkbox_j8UZx{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;margin-bottom:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-activation": `affiliate-activation_WsSAA`,
	"affiliate-activation__bottom": `affiliate-activation__bottom_rgNX4`,
	"affiliate-activation__bottom__checkbox": `affiliate-activation__bottom__checkbox_j8UZx`
};
export default ___CSS_LOADER_EXPORT___;
