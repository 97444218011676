// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-code-overview_fKcI2{margin-top:16px}.modal-content-inner_BDBW1{min-height:175px;padding:0 24px}.inner-modal_bMNGr{z-index:70;position:sticky}.pp-8__j2B7{padding:0 8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-code-overview": `bonus-code-overview_fKcI2`,
	"modal-content-inner": `modal-content-inner_BDBW1`,
	"inner-modal": `inner-modal_bMNGr`,
	"pp-8": `pp-8__j2B7`
};
export default ___CSS_LOADER_EXPORT___;
