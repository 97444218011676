// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .affiliate-profile_lgPXE{display:flex;flex-flow:row wrap;align-items:flex-start}html.app__layout--desktop .affiliate-profile__left_yS1Wx{display:grid;flex-shrink:0;grid-template-columns:100%;grid-row-gap:8px;width:100%;padding:16px 24px}html.app__layout--desktop .affiliate-profile__link_ibSyM,html.app__layout--desktop .affiliate-profile__nav_shAFh,html.app__layout--desktop .affiliate-profile__overview_lubta,html.app__layout--desktop .affiliate-profile__stats_uIYJv{color:var(--TextPrimary)}html.app__layout--desktop .affiliate-profile__tables_cH2rr{display:flex;flex-direction:column;grid-row:1/5;grid-column-start:2}html.app__layout--desktop .affiliate-profile__tables-top_dMM6m{line-height:24px}html.app__layout--desktop .affiliate-profile__tables-registrations_L9vtJ{margin-bottom:8px}html.app__layout--desktop .affiliate-profile__link_ibSyM,html.app__layout--desktop .affiliate-profile__stats_uIYJv{padding:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-profile": `affiliate-profile_lgPXE`,
	"affiliate-profile__left": `affiliate-profile__left_yS1Wx`,
	"affiliate-profile__link": `affiliate-profile__link_ibSyM`,
	"affiliate-profile__nav": `affiliate-profile__nav_shAFh`,
	"affiliate-profile__overview": `affiliate-profile__overview_lubta`,
	"affiliate-profile__stats": `affiliate-profile__stats_uIYJv`,
	"affiliate-profile__tables": `affiliate-profile__tables_cH2rr`,
	"affiliate-profile__tables-top": `affiliate-profile__tables-top_dMM6m`,
	"affiliate-profile__tables-registrations": `affiliate-profile__tables-registrations_L9vtJ`
};
export default ___CSS_LOADER_EXPORT___;
