// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.affiliate-program_sOdxc{padding:0 12px;margin-bottom:52px}.affiliate-program__loader_mY__J{margin-top:20px}.affiliate-program__reg_bAIJU{padding:0 8px 60px}html.app__layout--desktop .affiliate-program_sOdxc{padding:0 0 38px;margin-bottom:24px}html.app__layout--desktop .affiliate-program__reg_bAIJU{width:160px;padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-program": `affiliate-program_sOdxc`,
	"affiliate-program__loader": `affiliate-program__loader_mY__J`,
	"affiliate-program__reg": `affiliate-program__reg_bAIJU`
};
export default ___CSS_LOADER_EXPORT___;
