// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.affiliate-profile-migrated_l2nvG{padding-bottom:52px;margin:0 20px;text-align:center}.affiliate-profile-migrated__icon_xaGQg{margin-bottom:12px}.affiliate-profile-migrated__icon_xaGQg svg{fill:var(--TextSecondary)}.affiliate-profile-migrated__caption_jvwlx{font-size:18px;font-weight:500;line-height:24px;letter-spacing:normal;margin-bottom:8px;color:var(--TextDefault)}.affiliate-profile-migrated__text_rbnQJ{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-bottom:20px;color:var(--TextPrimary)}html.app__layout--desktop .affiliate-profile-migrated_l2nvG{margin:0 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-profile-migrated": `affiliate-profile-migrated_l2nvG`,
	"affiliate-profile-migrated__icon": `affiliate-profile-migrated__icon_xaGQg`,
	"affiliate-profile-migrated__caption": `affiliate-profile-migrated__caption_jvwlx`,
	"affiliate-profile-migrated__text": `affiliate-profile-migrated__text_rbnQJ`
};
export default ___CSS_LOADER_EXPORT___;
