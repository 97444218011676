// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.affiliate-stats_vrPfN{display:grid;grid-template-columns:1fr 1fr;row-gap:16px;background-color:var(--Layer1);border-radius:5px}.affiliate-stats__registration_SlKOI{cursor:pointer}.affiliate-stats__value_P88U2{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.affiliate-stats__value--brand-color_GRVTC{color:var(--BrandDefault)}.affiliate-stats__label_oOqGc{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-stats": `affiliate-stats_vrPfN`,
	"affiliate-stats__registration": `affiliate-stats__registration_SlKOI`,
	"affiliate-stats__value": `affiliate-stats__value_P88U2`,
	"affiliate-stats__value--brand-color": `affiliate-stats__value--brand-color_GRVTC`,
	"affiliate-stats__label": `affiliate-stats__label_oOqGc`
};
export default ___CSS_LOADER_EXPORT___;
