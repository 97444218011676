// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.affiliate-operation-details_tit6o{padding:0 8px 20px}.affiliate-operation-details__list_uXHnU{margin-top:8px;margin-bottom:8px;border-radius:5px}.affiliate-operation-details__list-item_oRUDp{display:flex;justify-content:space-between;width:100%;margin-bottom:2px}.affiliate-operation-details__list-item__left_a4gyO{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;color:var(--TextPrimary)}.affiliate-operation-details__list-item__right_GAuF2{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.affiliate-operation-details__list-item__right-red_EOjtf{color:var(--ErrorText)}.affiliate-operation-details__list-item__right-green_Idcv9{color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-operation-details": `affiliate-operation-details_tit6o`,
	"affiliate-operation-details__list": `affiliate-operation-details__list_uXHnU`,
	"affiliate-operation-details__list-item": `affiliate-operation-details__list-item_oRUDp`,
	"affiliate-operation-details__list-item__left": `affiliate-operation-details__list-item__left_a4gyO`,
	"affiliate-operation-details__list-item__right": `affiliate-operation-details__list-item__right_GAuF2`,
	"affiliate-operation-details__list-item__right-red": `affiliate-operation-details__list-item__right-red_EOjtf`,
	"affiliate-operation-details__list-item__right-green": `affiliate-operation-details__list-item__right-green_Idcv9`
};
export default ___CSS_LOADER_EXPORT___;
