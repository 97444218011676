// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-codes-list__caption_EaKC9{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;margin:24px 0 8px 8px;color:var(--TextDefault)}html.app__layout--desktop .bonus-codes-list__caption_EaKC9{margin-left:0}.bonus-codes-list__empty-description_VRN9c{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}.bonus-codes-list__body_KimDL{padding:0;margin:0;list-style:none}.bonus-codes-list__item_hp7C1{padding:0;margin:0 0 8px}.bonus-codes-list__button_TxCq2{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:flex;align-items:center;width:100%;padding:12px;overflow:hidden;color:var(--TextPrimary);text-align:left;cursor:pointer;background-color:var(--Layer1);border:none;border-radius:5px}.bonus-codes-list__button-text_u_J46{flex:1;margin-right:16px;word-break:break-word}.bonus-codes-list__button-icon_XYhUX{fill:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-codes-list__caption": `bonus-codes-list__caption_EaKC9`,
	"bonus-codes-list__empty-description": `bonus-codes-list__empty-description_VRN9c`,
	"bonus-codes-list__body": `bonus-codes-list__body_KimDL`,
	"bonus-codes-list__item": `bonus-codes-list__item_hp7C1`,
	"bonus-codes-list__button": `bonus-codes-list__button_TxCq2`,
	"bonus-codes-list__button-text": `bonus-codes-list__button-text_u_J46`,
	"bonus-codes-list__button-icon": `bonus-codes-list__button-icon_XYhUX`
};
export default ___CSS_LOADER_EXPORT___;
